<template>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      loader="bars"
      background-color="#ffffff"
      color="#006203"
      blur="15px"
      opacity="15.5"
    />
  </div>
  <div>
    <NavBar />
    <div id="main-container" class="container homePage mp-content">
      <div style="box-shadow: -4px 8px 24px rgba(44, 63, 88, 0.02)">
        <div class="row mt-4 top-img">
          <img :src="backgroundIMG" alt="" /><img src="" alt="" />
          <img class="charactImg" :src="charactImg" alt="" /><img
            src=""
            alt=""
          />
        </div>
        <div class="row">
          <div
            class="
              col-12
              d-flex
              flex-column
              justify-content-center
              align-items-center
              mx-0
            "
          >
            <div>
              <img :src="profileImg" alt="" class="profileCircle" />
            </div>
            <p class="mb-0" style="color: #006203">
              {{ user.userFirstName + " " + user.userLastName }}
            </p>
            <button class="btn btnLogout my-3" @click="logout">
              {{ profileLabel.logout_btn }}
            </button>
          </div>
        </div>
      </div>

      <div style="box-shadow: -4px 8px 24px rgba(44, 63, 88, 0.02)">
        <ul
          class="nav nav-tabs"
          id="myTab"
          role="tablist"
          :dir="!isLanguageEN ? 'rtl' : ''"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {{ profileLabel.general_Details_tab }}
            </button>
          </li>
        </ul>
        <div class="tab-content mx-0" id="myTabContent">
          <div
            class="tab-pane fade show active mx-0"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div
              class="row d-flex justify-content-end mx-0 my-2"
              :dir="!isLanguageEN ? 'rtl' : ''"
              id="btnGroupDiv"
            >
              <button
                class="btn btnChangePassword me-3"
                data-bs-toggle="modal"
                data-bs-target="#changePasswordModal"
                data-bs-whatever="@getbootstrap"
                @click="userID = '#' + user.userID"
              >
                {{ profileLabel.change_password }}
              </button>

              <button
                :class="isLanguageEN ? 'btn btnEdit' : 'btn AR-Lang-btnEdit'"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@getbootstrap"
                @click="userID = '#' + user.userID"
              >
                {{ profileLabel.edit_btn }}
              </button>
            </div>
            <div class="table-responsive">
              <table
                class="table table-borderless mx-0 w-100"
                :dir="!isLanguageEN ? 'rtl' : ''"
              >
                <tbody>
                  <tr>
                    <th class="col-2">{{ profileLabel.id }}:</th>
                    <td class="col-2">#{{ user.userID }}</td>
                  </tr>
                  <tr>
                    <th class="col-2">{{ profileLabel.name }}:</th>
                    <td class="col-2">
                      {{ user.userFirstName + " " + userLastName }}
                    </td>
                  </tr>
                  <tr>
                    <th class="col-2">{{ profileLabel.email_address }}:</th>
                    <td class="col-2">
                      {{ user.userEmail }}
                    </td>
                  </tr>
                  <tr>
                    <th class="col-2">{{ profileLabel.contact_Number }}:</th>
                    <td class="col-2">{{ user.userContact }}</td>
                  </tr>
                  <tr>
                    <th class="col-2">{{ profileLabel.language }}:</th>
                    <td>{{ user.userLanguage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade mx-0 w-100"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="table-responsive">
              <table class="table table-borderless mt-2 mx-0">
                <thead>
                  <th class="col-4 ps-2">
                    {{ profileLabel.change_Email_Address }}
                  </th>
                  <th class="col-4">{{ profileLabel.linked_Accounts }}</th>
                  <th class="col-4"></th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label class="mb-3 mt-2" for="userEmal">{{
                        profileLabel.email_address
                      }}</label>
                      <input
                        type="email"
                        id="userEmal"
                        class="form-control"
                        :value="user.userEmail"
                        disabled
                      />
                    </td>
                    <td>
                      <div class="d-flex mt-2">
                        <img
                          class="imgSize"
                          :src="googleIcon"
                          alt=""
                          height="30"
                        />
                        <p id="googleAc" class="ms-3">
                          {{ profileLabel.facebook }}
                        </p>
                      </div>

                      <label for="googleAc" class="ms-5">{{
                        profileLabel.deactivate
                      }}</label>
                    </td>
                    <td class="d-flex justify-content-end btnDiv mx-0">
                      <button class="btn btnLogout text-danger">
                        {{ profileLabel.disconnect }}
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <input
                        type="button"
                        id="btnUpdateEmail"
                        class="form-control"
                        value="Update Email"
                      />
                    </td>
                    <td>
                      <div class="d-flex mt-2 mx-0">
                        <img
                          class="imgSize"
                          :src="facebookIcon"
                          alt=""
                          height="30"
                        />
                        <p id="googleAc" class="ms-3">
                          {{ profileLabel.facebook }}
                        </p>
                      </div>

                      <label for="googleAc" class="ms-5">{{
                        profileLabel.deactivate
                      }}</label>
                    </td>
                    <td class="d-flex justify-content-end btnDiv mx-0">
                      <button class="btn btnLogout">
                        {{ profileLabel.connect }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ profileLabel.update_Your_Profile }}
              </h5>
              <button
                type="button"
                class="btn-close bg-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form :dir="!isLanguageEN ? 'rtl' : ''">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerID"
                    :placeholder="profileLabel.passenger_ID"
                    v-model="userID"
                    readonly
                    disabled
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerEmail"
                    :placeholder="profileLabel.passenger_Email"
                    v-model="userEmail"
                    disabled
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerFName"
                    :placeholder="profileLabel.passenger_First_Name"
                    v-model="userFirstName"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerLName"
                    :placeholder="profileLabel.passenger_Last_Name"
                    v-model="userLastName"
                  />
                </div>

                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerContact"
                    :placeholder="profileLabel.contact_Number"
                    v-model="userContact"
                    :disabled="!this.UBPJC"
                    @change="checkMobileNumber($event)"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer" :dir="!isLanguageEN ? 'rtl' : ''">
              <button
                id="updteClose"
                type="button"
                class="btn bg-danger"
                data-bs-dismiss="modal"
              >
                {{ profileLabel.close }}
              </button>
              <button
                id="updteButton"
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                @click="updateUserProfile"
              >
                {{ profileLabel.update_Profile }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- change password modal -->
      <div
        class="modal fade"
        id="changePasswordModal"
        tabindex="-1"
        aria-labelledby="changePasswordModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="changePasswordModalLabel">
                {{ profileLabel.update_Your_Profile }}
              </h5>
              <button
                type="button"
                class="btn-close bg-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="clearField()"
              ></button>
            </div>
            <div class="modal-body">
              <form :dir="!isLanguageEN ? 'rtl' : ''">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerID"
                    :placeholder="profileLabel.passenger_ID"
                    v-model="userID"
                    readonly
                    disabled
                  />
                </div>

                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="passengerEmail"
                    :placeholder="profileLabel.passenger_Email"
                    v-model="userEmail"
                    disabled
                  />
                </div>

                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="passengerFName"
                    :placeholder="profileLabel.password"
                    v-model="password"
                  />
                </div>

                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="passengerFName"
                    :placeholder="profileLabel.new_password"
                    v-model="newPassword"
                    @keyup="checkPasswordValidation($event)"
                    @blur="checkPasswordValidationBlurFnc($event)"
                  />
                </div>
                <div id="new_pswd_info" class="mb-2">
                  <ul>
                    <li id="length" class="invalid">
                      Be at least <strong>8 characters</strong>
                    </li>
                    <li id="number" class="invalid">
                      At least <strong>one number</strong>
                    </li>
                    <li id="letter" class="invalid">
                      <strong>one lowwercase letter</strong>
                    </li>
                    <li id="capital" class="invalid">
                      <strong>one uppercase letter</strong>
                    </li>
                    <li id="symbol" class="invalid">
                      <strong>Symbol !@#$%^&*</strong>
                    </li>
                  </ul>
                </div>

                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="passengerFName"
                    :placeholder="profileLabel.confirm_password"
                    v-model="confPassword"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer" :dir="!isLanguageEN ? 'rtl' : ''">
              <button
                id="updteClose1"
                type="button"
                class="btn bg-danger"
                data-bs-dismiss="modal"
                @click="clearField()"
              >
                {{ profileLabel.close }}
              </button>
              <button
                id="updteButton"
                type="button"
                class="btn"
                @click="updatePassword"
              >
                {{ profileLabel.update_Password }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backgroundIMG from "../assets/img/userProfileBackground.svg";
import charactImg from "../assets/img/Group 482.svg";
import profileImg from "../assets/img/profileImage.png";
import googleIcon from "../assets/Icons/google-icon.png";
import facebookIcon from "../assets/Icons/facebook.png";
import vuex from "vuex";
import VueSweetalert2 from "sweetalert2";
import NavBar from "../common/components/booking/NavBar.vue";
import UserService from "../services/UserService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "UserProfile",
  components: {
    NavBar,
    Loading,
  },
  data() {
    return {
      profileLabel: this.$store.getters.getProfileDetails,
      backgroundIMG,
      charactImg,
      profileImg,
      googleIcon,
      facebookIcon,
      userID: "",

      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userContact: "",
      userLanguage: "",
      user: {},
      isLanguageEN: true,
      password: "",
      newPassword: "",
      confPassword: "",
      isLoading: false,
      fullPage: true,
      correctPasswordPolicy: false,
      UBPJC: false,
    };
  },
  computed: {
    // ...vuex.mapGetters(["currentUser"]),
  },
  mounted() {
    this.isLoading = true;
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser) {
      this.getUserDetails(currentUser.userId);
    }
    const UBPJC = localStorage.getItem("UBPJC");
    if (UBPJC == true) {
      this.UBPJC = true;
    } else {
      this.UBPJC = false;
    }
  },

  methods: {
    ...vuex.mapActions(["getUserProfileDetails"]),
    ...vuex.mapGetters(["userProfile"]),
    ...vuex.mapActions(["clearUser"]),
    ...vuex.mapActions(["updateUser"]),

    getUserDetails(userId) {
      UserService.getUserDetails(userId)
        .then((response) => {
          if (response) {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);

            const userDetails = {
              userID: response.data.systemUserID,
              userFirstName: response.data.firstName,
              userLastName: response.data.lastName,
              userEmail: response.data.email,
              userContact: response.data.phoneNumber,
              userLanguage: "",
            };

            if (localStorage.getItem("lang") === "EN") {
              userDetails.userLanguage = "English";
            } else {
              userDetails.userLanguage = "العربية";
            }

            this.userFirstName = response.data.firstName;
            this.userLastName = response.data.lastName;
            this.userContact = response.data.phoneNumber;
            this.userEmail = response.data.email;

            this.user = userDetails;

            const user = {
              email: response.data.email,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              userId: response.data.systemUserID,
              username: response.data.userName,
            };
            this.updateUser(JSON.stringify(user));
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
          if (
            error.response.data.errors[0].message == "This user does not exist."
          ) {
            this.springLoader = false;
            this.invalidIdentity = true;
          }
        });
    },
    logout() {
      this.clearUser();
      setTimeout(function () {
        sessionStorage.removeItem("currentUser");
        window.location.href = "/login";
      }, 500);
    },
    clearField() {
      this.password = "";
      this.newPassword = "";
      this.confPassword = "";
    },
    updateUserProfile() {
      if (
        this.userContact &&
        /^\+[1-9]{1}[0-9]{3,14}$/.test(this.userContact)
      ) {
        const profile = {
          firstName: this.userFirstName,
          lastName: this.userLastName,
          phoneNumber: this.userContact,
        };

        UserService.updateUserProfile(profile, this.user.userID)
          .then((response) => {
            if (response.data) {
              VueSweetalert2.fire({
                toast: false,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                title: "Your profile has been updated successfully.",
                icon: "success",
              });

              setTimeout(function () {
                getUserDetails(this.user.userID);
                let modalClose = document.getElementById("updteClose");
                modalClose.click();
              }, 1500);
            }
          })
          .catch((error) => {
            VueSweetalert2.fire({
              toast: false,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
              title: error.response.data.errors[0].message,
              icon: "error",
            });
          });
      } else {
        VueSweetalert2.fire({
          toast: false,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          title: "Please input valid mobile number.",
          icon: "error",
        });
      }
    },

    updatePassword() {
      if (this.newPassword !== "") {
        if (this.correctPasswordPolicy) {
          if (this.newPassword === this.confPassword) {
            UserService.changePassword(
              this.user.userID,
              this.password,
              this.newPassword
            )
              .then((res) => {
                if (res) {
                  this.clearField();
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "Password has been reset successfully.",
                    icon: "success",
                  });
                  this.correctPasswordPolicy = false;
                  let modalClose = document.getElementById("updteClose1");
                  modalClose.click();
                } else {
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "Something went wrong. Password reset failed.",
                    icon: "error",
                  });
                }
              })
              .catch((error) => {
                if (
                  error.response.data.errors[0].message ==
                  "The old and new passwords must not be the same."
                ) {
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    title:
                      "Please try a new password. You are unable to use the same password.",
                    icon: "error",
                  });
                }
              });
          } else {
            VueSweetalert2.fire({
              toast: false,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
              title: "Confirm password is not matched.",
              icon: "error",
            });
          }
        } else {
          VueSweetalert2.fire({
            toast: false,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
            title:
              "Password should be minimum 8 characters, at least one upper case, one lower case, one number and one special character.",
            icon: "error",
          });
        }
      } else {
        VueSweetalert2.fire({
          toast: false,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          title: "Insert new password.",
          icon: "error",
        });
      }
    },

    checkPasswordValidation(event) {
      $("#new_pswd_info").show();
      let validCount = 0;

      //validate the length
      if (event.target.value.length < 8) {
        $("#length").removeClass("valid").addClass("invalid");
        validCount--;
      } else {
        $("#length").removeClass("invalid").addClass("valid");
        validCount++;
      }

      //validate letter
      if (event.target.value.match(/[a-z]/)) {
        $("#letter").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#letter").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate capital letter
      if (event.target.value.match(/[A-Z]/)) {
        $("#capital").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#capital").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate number
      if (event.target.value.match(/\d/)) {
        $("#number").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#number").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate symbol
      if (event.target.value.match(/[*@!#%&()^~{}]+/)) {
        $("#symbol").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#symbol").removeClass("valid").addClass("invalid");
        validCount--;
      }
      if (validCount === 5) {
        this.correctPasswordPolicy = true;
      }
    },

    checkPasswordValidationBlurFnc(event) {
      $("#new_pswd_info").hide();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
#new_pswd_info {
  /* position: absolute; */
  top: 210px;
  bottom: -115px; /* IE Specific */
  right: 0;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}

#new_pswd_info::before {
  content: "\25B2";
  position: relative;
  top: -32px;
  left: 5%;
  font-size: 14px;
  line-height: 14px;
  color: #ddd;
  text-shadow: none;
  display: block;
}

.invalid {
  background: url(../assets/Icons/invalid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #ec3f41;
}
.valid {
  background: url(../assets/Icons/valid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #3a7d34;
}

#new_pswd_info {
  display: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(0, 98, 3) / 25% !important;
}

#updteButton {
  background: #006203;
  color: #ffffff;
  width: 170px;
  height: 38px;
  border-radius: 13px;
}

#updteClose {
  background: #db0e03;
  color: #ffffff;
  width: 140px;
  height: 38px;
  border-radius: 13px;
}
#updteClose1 {
  background: #db0e03;
  color: #ffffff;
  width: 140px;
  height: 38px;
  border-radius: 13px;
}

.btnDiv {
  margin-right: 37px;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(0, 98, 3) / 25% !important;
}

#btnUpdateEmail {
  border-radius: 13px;
  background: #006203;
  width: 250px;
  color: #ffffff;
  margin-top: 20px;
  opacity: 50%;
}

input[type="email"] {
  border-radius: 13px;
  background: #ffffff;
  width: 250px;
}
input:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
}

.userLabel {
  margin-top: 50px;
}

.nav-link {
  color: #595f69;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #006203;
  border-bottom: 1px solid #006203;
  /* border-top: none; */
}

#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

.btnLogout {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #6369729a;
  width: 120px;
  height: 40px;
  font-weight: 500px;
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
.btnEdit {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #6369729a;
  width: 120px;
  height: 40px;
  font-weight: 500px;
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.AR-Lang-btnEdit {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #6369729a;
  margin-right: 12px !important;
  width: 120px;
  height: 40px;
  font-weight: 500px;
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.btnChangePassword {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #6369729a;
  width: 180px;
  height: 40px;
  font-weight: 500px;
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.profileCircle {
  position: relative;
  width: 128px !important;
  height: 128px !important;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: -96px;
}
.profileDiv {
  height: 120px;
  background: #ffffff;
}

.charactImg {
  position: absolute;
  top: 0px;
  width: 187.24px;
  height: 188.4px;
  right: 0;
}

.main-section {
  position: absolute;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  left: 200px !important;
}

.homePage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  right: 0px;
  margin: auto;
  z-index: 15;
  width: 100%;
  height: auto;
}

.email-td {
  text-overflow: clip !important;
}
.closed {
  width: calc(100% - 102px) !important;
  margin-left: 100px !important;
  transition: all 0.5s ease;
}
.mp-content {
  width: calc(100% - 340px);
  margin-left: 269px;
  transition: all 0.5s ease;
  padding: 0px;
}
@media (max-width: 768px) {
  .mp-sidebar {
    width: 70px;
  }

  .mp-content {
    width: calc(100% - 70px);
    margin-left: 70px;
    float: left;
  }
}
@media all and (max-width: 1024px) {
}

@media all and (max-width: 991px) {
  .main-section {
    left: 110px !important;
  }
}
@media (min-width: 425px) and (max-width: 599px) {
  .homePage {
    width: 85% !important;
    right: 150px !important;
    left: 50px !important;
  }
}
@media all and (max-width: 425px) {
  .main-section {
    left: 0 !important;
  }
  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }
  #btnGroupDiv {
    display: flex !important;
    justify-content: space-around !important;
  }
  .homePage {
    width: 85% !important;
    right: 0px !important;
    left: 50px !important;
  }
}
@media all and (max-width: 510px) {
  .top-img {
    display: none;
  }
  .profileCircle {
    margin-top: 0px;
  }
  .main-section {
    left: 0 !important;
  }
}
</style>
